import { getHistory } from '../store';
import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../../firebase";
import moment from "moment";
import { collection, doc, getDoc } from "firebase/firestore";

const prefix = 'AUTH';

const authActions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,
  
  AUTH_INIT_START: `${prefix}_INIT_START`,
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,
  
  AUTH_CONFIRM_SENT: `${prefix}_CONFIRM_SENT`,
  
  AUTH_LOGOUT_SUCCESS: `${prefix}_LOGOUT_SUCCESS`,
  
  AUTH_LOADING: `${prefix}_LOADING`,
  AUTH_LOADING_STOP: `${prefix}_LOADING_STOP`,
  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,
  
  BALANCE_FETCH_SUCCESS: `${prefix}_BALANCE_FETCH_SUCCESS`,
  BALANCE_FETCH_ERROR: `${prefix}_BALANCE_FETCH_ERROR`,
  
  doInit: () => async (dispatch) => {
    console.log('doint')
    try {
      dispatch({
        type: authActions.AUTH_INIT_START,
        payload: {},
      });
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const accountsRef = collection(db, "user");
          const userData = await getDoc(doc(accountsRef, user.uid));
          let registered = false
          if (userData.exists())
            registered = !!userData.data().accountId
          dispatch({
            type: authActions.AUTH_INIT_SUCCESS,
            payload: {
              currentUser: user,
              registered,
              billing: {}
            },
          });
        } else {
          /*const cookies = new Cookies();
          const isCodeSent = cookies.get('codeSent');
          if (isCodeSent) {
            dispatch({
              type: authActions.AUTH_CONFIRM_SENT,
              payload: {confirmSent: true}
            });
          }*/
          dispatch({
            type: authActions.AUTH_INIT_ERROR,
            payload: {
              registered: false,
              currentUser: null,
              billing: {}
            },
          });
        }
      });
    } catch (e) {
      dispatch({
        type: authActions.AUTH_INIT_ERROR,
      });
    }
  },
  loading: () => async (dispatch) => {
    dispatch({
      type: authActions.AUTH_LOADING,
      payload: {},
    });
  },
  fetchBalance: () => async (dispatch, getState) => {
    try {
      const currentUser = await getState().auth && getState().auth.currentUser ? getState().auth.currentUser : null;
      const response = await axios.get(`https://ahdnf7a6g9.execute-api.us-east-2.amazonaws.com/auth/balance?user=${currentUser.username}`,
        {
          headers: {
            'authorization': currentUser.signInUserSession.idToken.jwtToken
          }
        }
      );
      await dispatch({
        type: authActions.BALANCE_FETCH_SUCCESS,
        payload: {
          billing: response.data,
        },
      });
    } catch (error) {
      dispatch({
        type: authActions.BALANCE_FETCH_ERROR,
        payload: { billing: {} },
      });
    }
  },
  stopLoading: () => async (dispatch) => {
    dispatch({
      type: authActions.AUTH_LOADING_STOP,
      payload: {},
    });
  },
  updateConfirmSent: (sent) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.AUTH_CONFIRM_SENT,
        payload: { confirmSent: sent },
      });
    } catch (e) {
      console.log(e)
    }
  },
  updateAuth: (route) => async (dispatch) => {
    /*try {
      dispatch({
        type: authActions.AUTH_INIT_START,
        payload: {},
      });
      const currentUser = await Auth.currentAuthenticatedUser();

      if (currentUser) {
        Cohere.identify(
          currentUser.username, // Required: can be any unique ID
          {
            displayName: currentUser.attributes.name, // Optional
            email: currentUser.attributes.email, // Optional
          }
        );
        await dispatch({
          type: authActions.AUTH_INIT_SUCCESS,
          payload: {
            currentUser,
          },
        });
      } else {
        dispatch({
          type: authActions.AUTH_INIT_ERROR,
        });
      }
      getHistory().replace(route ? route : "/blog-upload");

    } catch (error) {
      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }*/
  },
  doLogout: () => async (dispatch) => {
    try {
       await auth.signOut()
       await dispatch({
         type: authActions.AUTH_LOGOUT_SUCCESS,
         payload: {},
       });
      getHistory().push("/login");
    } catch (error) {
    }
  }
  
};


export default authActions;
