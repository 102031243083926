import appActions from "./appActions";

const initialData = {
  jobs: [],
  loading: false,
  errorMessage: null,
  account: {},
  registered: false,
  user: {},
  positions: [],
  orders: [],
  pets: [],
};

export default (state = initialData, {type, payload}) => {

  if (type === appActions.LOADING) {
    return {
      ...state,
      loading: payload.loading
    }
  }
  else if (type === appActions.REGISTER_SUCCESS) {
    return {
      ...state,
      loading: false,
      registered: true,
    }
  }
  else if (type === appActions.ON_SIGNUP) {
    return {
      ...state,
      loading: false,
      registered: payload.registered,
      user: payload.user,
    }
  }
  else if (type === appActions.ON_LOGIN) {
    return {
      ...state,
      loading: false,
      registered: payload.registered,
      user: payload.user,
    }
  }
  else if(type === appActions.ON_ACCOUNT_DATA){
    return {
      ...state,
      loading: false,
      user: payload.user,
    }
  }
  else if(type === appActions.PETS){
    return {
      ...state,
      loading: false,
      pets: payload.pets,
    }
  }
  else if (type === appActions.CLEAN_SESSION) {
    return {
      ...state,
      registered: false,
      user: null
    }
  }
  else if (type === appActions.PORTFOLIO_SUCCESS) {
    return {
      ...state,
      loading: false,
      positions: payload.positions,
      orders: payload.orders,
      account: payload.account
    }
  }
  return state;
}
