import styled, { css } from "styled-components";
export const SectionWrapper = styled("div")`
  min-height: 100vh;
`;
export const WrapperLeft = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background: #5a6ffb;
  img {
    margin-top: 40px;
  }
  ${(props) =>
    props.bgColor === "secoundary" &&
    css`
      background: #f9f9fc;
    `}
`;
export const WrapperLeftInner = styled.div`
  position: relative;
  text-align: center;
  .image-no-margin {
    margin-top: 0;
  }
  .left__content__main--heading {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 44.7539px;
    line-height: 54px;
    text-align: center;
    margin-bottom: 10px;
    color: #2c304b;
    b {
      font-weight: 800;
    }
    img {
      margin-left: 10px;
      position: relative;
      top: 5px;
    }
  }
  .left__content__main--para {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #7c82a7;
    margin-bottom: 30px;
  }
`;
export const WrapperRight = styled("div")`
  background: #ffffff;
  min-height: 100vh;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo {
    @media (max-width: 768px) {
      width: 90px;
    }
  }
  @media (max-width: 768px) {
    padding-bottom: 30px;
  }
`;
export const WrapperRightInner = styled("div")`
 width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0px;
  @media (max-width: 768px) {
    padding-top: 10px;
    height: unset;
  }
`;
export const SectionTopContent = styled("div")`
  margin-bottom: 40px;
  .image-left-margin {
    margin-left: 10px;
    width: 22px;
  }
  h2 {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 44.7539px;
    line-height: 54px;
    color: #2c304b;
    margin-bottom: 10px;
  }
  .text-center {
    text-align: center;
  }
  h2.negative__margin {
    @media (min-width: 992px) {
      margin-left: -40px;
      margin-right: -40px;
    }
  }
  h2.top__margin {
    margin-top: 100px;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
  p.para__main {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #7c82a7;
    margin-bottom: 8px;
  }
  p.para__secondary {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    text-align: center;
    color: #707070;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;
    h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`;
