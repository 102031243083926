import { Select, Col, Form, Modal as AntModal, Steps, Input, Row, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
//import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from 'react-select'
import StateData from './../../data.json'
import authSelector from "../../modules/auth/authSelectors";
import appActions from "../../modules/app/appActions";
import { codes } from "../../Utils";
import appSelector from "../../modules/app/appSelectors";
import "react-datepicker/dist/react-datepicker.css";

const { Option } = Select;


const PersonalInformation = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const user = useSelector(appSelector.selectUser)
  const [form] = Form.useForm();
  const [state, setState] = useState('')
  
  useEffect(() => {
    if(currentUser) {
      dispatch(appActions.getAccount(currentUser))
    }
  }, [currentUser]);
  
  console.log('currentUser',user)
  
  useEffect(() => {
    if(user && user.firstName){
      form.setFieldsValue({
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        state: user?.userContact?.state,
        city: user?.userContact?.city,
        postalCode: user?.userContact?.postalCode,
        address: user?.userContact?.street,
        phone: user?.userContact?.phone
      });
    }
  }, [user])
  
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select defaultValue={"1"} value={"1"} style={{ width: 70 }}>
        <Option value="1">+1</Option>
      </Select>
    </Form.Item>
  );
  const onFinish = (values)=>{
    console.log(values)
    const user = {
      firstName: values.first_name,
      lastName: values.last_name,
      middleName: values.middle_Name ? values.middle_Name :'',
      userContact:{
        email: currentUser.email,
        phone: values.phone,
        city: values.city.value,
        state: codes[values.state],
        street: values.address,
        postalCode: values.postalCode
      }
    }
    dispatch(appActions.updateUserData(currentUser.uid, user))
    props.onNext()
  }
  
  console.log('state', StateData[state])
  
  return (
    <Row justify={'center'} align={'center'}>
      <Col sm={20}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={[]}
          onFinish={onFinish}
        >
          <Form.Item
            rules={[{ required: true, message: 'First Name is required' }]}
            label="First Name" name={'first_name'}>
            <Input/>
          </Form.Item>
          <Form.Item
            label="Middle Name" name={'middle_name'}>
            <Input/>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Last Name is required' }]}
            label="Last Name" name={'last_name'}>
            <Input/>
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[{ required: true, message: 'Please input your phone number!' }]}
          >
            <Input type={'number'} addonBefore={prefixSelector} maxLength={10}/>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Address is required' }]}
            label="Address" name={'address'}>
            <Input/>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'State is required' }]}
            label="State" name={'state'}>
            <Select
              showSearch
              placeholder={'Select your state'}
              options={Object.keys(StateData).map((key) => ({ label: key, value: key }))}
              onChange={(value, option) => {
                setState(value)
                form.setFieldsValue({ city: null })
              }}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'City is required' }]}
            label="City" name={'city'}>
            <ReactSelect
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  textAlign:'left'
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  textAlign:'left'
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  textAlign:'left'
                })
              }}
              placeholder={'Select your city'}
              options={state && StateData[state] ? StateData[state].map((key)=>({label:key, value: key})) : []}
            >
            </ReactSelect>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Valid Postal code is required', max:5 }]}
            label="Postal code" name={'postalCode'}>
            <Input type={"number"}/>
          </Form.Item>
          
          <Col span={18} offset={6} style={{marginTop: 50}}>
            <Form.Item>
              <Button block type="primary" htmlType="submit" size={"large"}>
                Continue
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default PersonalInformation;
