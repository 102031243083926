import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Table, Button, Layout, Menu, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import authActions from "../../modules/auth/authActions";
import appActions from "../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import appSelector from "../../modules/app/appSelectors";
import Loading from "../../components/Loading";
import { AccountHeader } from "../../components/Account/AccountHeader";
import NewRequest from "../../components/NewRequest";
import LinkPlaid from "../../components/LinkPlaid";
import authSelector from "../../modules/auth/authSelectors";
import Pets from "./Pets";

const { Header, Content, Footer, Sider } = Layout;

const MENU_OPTIONS = [
  {
    key: 'MyAccount',
    label: `My Account`,
  },
  {
    key: 'Positions',
    label: `Pets`,
  },
  {
    key: 'Profile',
    label: `Profile`,
  }
]

const Home = (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const user = useSelector(appSelector.selectUser)
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const positions = useSelector(appSelector.selectPositions)
  const pets = useSelector(appSelector.selectPets)
  const [selectedMenu, setSelectedMenu] = useState('MyAccount')
 
  console.log('user data', pets)
  useEffect(() => {
    if(user && user.accountId){
      dispatch(appActions.getPortfolio(user.accountId))
      //dispatch(appActions.getAccount(user))
      dispatch(appActions.getPets(currentUser?.uid))
    }
    else if(user?.uid) {
      dispatch(appActions.getAccount(user))
      dispatch(appActions.getPets(user?.uid))
    }
    else if(currentUser?.uid){
      dispatch(appActions.getAccount(currentUser))
      dispatch(appActions.getPets(currentUser?.uid))
    }
    else {
      history.push('/login')
    }

  }, [user])
  

  
  const orderColumns = [
    {
      title: 'Name',
      dataIndex: 'symbol',
      key: 'symbol'
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      key: 'qty'
    },
    {
      title: 'Type',
      dataIndex: 'side',
      key: 'side',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },

  ]
  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={broken => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo">
          <img
            className={'matty-logo'}
            src={require('./../../assets/skytails_logo.png')}/>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[selectedMenu]}
          items={MENU_OPTIONS}
          onSelect={(item) => {
            console.log(item)
            setSelectedMenu(item.key)
          }}
        />
      </Sider>
      <Layout>
        <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
          <Button
            onClick={() => {
              dispatch(authActions.doLogout())
              dispatch(appActions.cleanSession())
            }}
            style={{ position: 'absolute', right: 20, top: 20 }}
            type="outline">Logout</Button>
        </Header>
        <Content style={{ margin: '24px 16px 0' }}>
          {selectedMenu === 'MyAccount' &&
            <AccountHeader/>
          }
          {selectedMenu === 'Positions' && <Pets/>}
          {selectedMenu === 'Profile' && <LinkPlaid userId={user.id} accountId={user.accountId} name={user.name}/>}
        </Content>
        <Footer style={{ textAlign: 'center' }}></Footer>
      </Layout>
      <Loading />
    </Layout>
  );
}


export default Home;
