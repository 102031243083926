import axios from "axios";
import { randomIntFromInterval } from "../../Utils";
import { db, firebase } from "../../firebase";
import {
  collection,
  doc,
  setDoc,
  addDoc,
  getDocs,
  query,
  where,
  getDoc,
  updateDoc,
  deleteDoc
} from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { message } from "antd";

const prefix = 'APP';
const snooze = ms => new Promise(resolve => setTimeout(resolve, ms));
const appActions = {
  LOADING: `${prefix}_LOADING`,
  REGISTER_SUCCESS: `${prefix}_REGISTER_SUCCESS`,
  BLOG_FETCH_SUCCESS: `${prefix}_BLOGS_FETCH_SUCCESS`,
  BLOG_FETCH_ERROR: `${prefix}_BLOGS_FETCH_ERROR`,
  CLEAN_SESSION: `${prefix}_CLEAN_SESSION`,
  ON_SIGNUP: `${prefix}_ON_SIGNUP`,
  ON_LOGIN: `${prefix}_ON_LOGIN`,
  ON_ACCOUNT_DATA: `${prefix}_ON_ACCOUNT_DATA`,
  PORTFOLIO_SUCCESS: `${prefix}_PORTFOLIO_SUCCESS`,
  PETS: `${prefix}_PETS`,
  updateUserData: (uid, data) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        console.log('uid', uid, data)
        const docRef = doc(db, "user", uid);
        await updateDoc(docRef, { ...data });
        message.info(`Updated successfully`)
        resolve(`Updated successfully`)
      } catch (e) {
        console.log('e', e)
        message.error(e)
        reject(e)
      }
    })
  },
  getAccount: (user) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const accountsRef = collection(db, "user");
        const userData = await getDoc(doc(accountsRef, user.uid));
        if (userData.exists()){
          dispatch({
            type: appActions.ON_ACCOUNT_DATA,
            payload:{
              user: userData.data(),
            }
          });
        }
      }catch (e) {
        console.log('e',e, user)
      }
    })
  },
  
  onLogin: (user) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const accountsRef = collection(db, "user");
        const userData = await getDoc(doc(accountsRef, user.uid));
        let registered = false
        console.log('user', userData.data(), !!userData.data().accountId)
        if (userData.exists()) {
          registered = !!userData.data().accountId
          dispatch({
            type: appActions.ON_LOGIN,
            payload: {
              user: userData.data(),
              registered
            }
          });
          resolve(userData.data());
        }
      }catch (e) {
        console.log(e)
        reject(e);
      }
    })
  },
  registerAccount: (user) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        const accountsRef = collection(db, "user");
        await setDoc(doc(accountsRef, user.uid), {
          email: user.email,
          name: user.displayName,
          uid: user.uid,
          id: user.uid,
          firstName: user.first_name,
          lastName: user.last_name,
        })
        const userData = await getDoc(doc(accountsRef, user.uid));
        let registered = false
        if (userData.exists())
          registered = !!userData.data().accountId
        dispatch({
          type: appActions.ON_SIGNUP,
          payload:{
            user: userData.data(),
            registered
          }
        });
        resolve(true);
      } catch (e) {
        console.log(e)
        reject(e);
      }
    })
  },
  cleanSession: () => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      dispatch({
        type: appActions.CLEAN_SESSION
      });
    })
  },
  addPet: (user_id,pet) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: { loading: true },
        });
        if(pet.id){
          const docRef = doc(db, "pets", pet.id);
          await updateDoc(docRef,{
            ...pet,
          });
        }
        else {
          const accountsRef = collection(db, "pets");
          await addDoc(accountsRef, {
            user_id: user_id,
            ...pet,
          });
        }
        dispatch({
          type: appActions.LOADING,
          payload: { loading: false },
        });
        resolve(true);
      } catch (e) {
        console.log(e)
        reject(e);
      }
    })
  },
  updatePets: (pets) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: { loading: true },
        });
        await Promise.all(pets.map(async (pet)=>{
          const docRef = doc(db, "pets", pet.id);
          await updateDoc(docRef,{
            ...pet,
          });
        }))
        dispatch({
          type: appActions.LOADING,
          payload: { loading: false },
        });
        resolve(true);
      } catch (e) {
        console.log(e)
        reject(e);
      }
    })
  },
  deletePet: (id) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: { loading: true },
        });
        const docRef = doc(db, "pets", id);
        await deleteDoc(docRef);
        dispatch({
          type: appActions.LOADING,
          payload: { loading: false },
        });
        resolve(true);
      } catch (e) {
        console.log(e)
        reject(e);
      }
    })
  },
  
  getPets: (user_id) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
       /* dispatch({
          type: appActions.LOADING,
          payload: { loading: true },
        });*/
        const q = query(collection(db, 'pets'), where('user_id', '==', user_id));
        
        // Execute the query
        const querySnapshot = await getDocs(q);
        const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        dispatch({
          type: appActions.PETS,
          payload: { 'pets': documents },
        });
        resolve(true);
      } catch (e) {
        console.log(e)
        reject(e);
      }
    })
  },
  
  createAccount: (uid) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: { loading: true },
        });
        const response = await axios.post('https://us-central1-matty-628bf.cloudfunctions.net/api/create-web-account-skytails', {
          data: { uid }
        })
        if (response.status === 200 && response.data.ok) {
          dispatch({
            type: appActions.REGISTER_SUCCESS,
            payload: {},
          })
          message.info(`You are successfully registered`)
          resolve(true)
        }
        else if(response.status === 200 && response.data) {
          message.error(response.data.message)
          resolve(false)
          dispatch({
            type: appActions.LOADING,
            payload: { loading: false },
          });
        }
      } catch (e) {
        message.error(e)
        resolve(false)
        dispatch({
          type: appActions.LOADING,
          payload: { loading: false },
        });
      }
    })
  },
  getPortfolio: (accountId) => async (dispatch, getState) => {
    console.log('accountId',accountId)
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: true
          },
        })
        const response = await axios.post('https://us-central1-matty-628bf.cloudfunctions.net/api/get-portfolio', {
          accountId
        })
        if (response.status === 200 && response.data.ok) {
          dispatch({
            type: appActions.PORTFOLIO_SUCCESS,
            payload: {
              account: response.data.account,
              positions: response.data.positions,
              orders: response.data.orders
            },
          })
        }
        else {
          dispatch({
            type: appActions.LOADING,
            payload: {
              loading: false
            },
          })
        }
      } catch (e) {
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: false
          },
        })
      }
    })
  },
  createTransfer: (accountId, amount, id, achId, policy) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: true
          },
        })
        const response = await axios.post('https://us-central1-matty-628bf.cloudfunctions.net/api/transfer-funds', {
          accountId, amount, id, achId, policy
        })
        dispatch({
          type: appActions.LOADING,
          payload: {
            loading: false
          },
        })
        if (response.status === 200 && response.data.ok) {
          resolve(true);
        }
        else {
          reject(response.data.message);
        }
      } catch (e) {
        reject(e);
      }
    })
  },
  forgotPassword: (email) => async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
       const emailSent = await sendPasswordResetEmail(getAuth(), email)
        console.log('email', emailSent)
        resolve(true)
      } catch (e) {
        console.log(e)
        message.error(e)
        reject(e)
      }
    })
  },
};

export default appActions;
