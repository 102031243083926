import {createSelector} from 'reselect';

const selectRaw = (state) => state.app;

const selectIsRegistered = createSelector(
  [selectRaw],
  (app) => app.registered,
);
const selectLoading = createSelector(
  [selectRaw],
  (app) => app.loading,
);
const selectUser = createSelector(
  [selectRaw],
  (app) => app.user,
);
const selectPositions = createSelector(
  [selectRaw],
  (app) => app.positions,
);
const selectOrders = createSelector(
  [selectRaw],
  (app) => app.orders,
);
const selectAccount = createSelector(
  [selectRaw],
  (app) => app.account,
);
const selectPets = createSelector(
  [selectRaw],
  (app) => app.pets,
);
const selectors = {
  selectRaw,
  selectIsRegistered,
  selectLoading,
  selectUser,
  selectOrders,
  selectPositions,
  selectAccount,
  selectPets
};

export default selectors;
