import { Upload, Button, Col, DatePicker, Form, Input, Row, Select, message,Modal } from "antd";
import React, { useEffect, useState } from "react";
//import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {uploadBytes, getDownloadURL} from "firebase/storage";

import uuid from "uuid-v4";
import appActions from "../../modules/app/appActions";
import authSelector from "../../modules/auth/authSelectors";
import {storage, ref} from "../../firebase";

const { Option } = Select;
const OPTIONS = [
  { label: 'Passport', value: 'passport' },
  { label: 'Driving license', value: 'driver_license' },
  { label: 'Government ID', value: 'government_id' },
]

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

const FinancialInformation = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const [frontUrl, setFrontUrl] = useState('')
  const [backUrl, setBackUrl] = useState('')
  
  const uploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file) {
      console.log('file', file)
      if(file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg'){
        return true;
      }
      Modal.error({
        title: 'Unsupported Image type',
        content: 'Only pn, jpeg images are supported',
      });
      return false;
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    
    async customRequest({onError, onSuccess, file, filename, data}) {
      if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
        const type = file?.type?.split('/').length > 1 ? file?.type?.split('/')[1] : 'jpeg';
        const token = uuid()
        const metadata = {
          contentType: `image/${type}`,
          metadata: {
            firebaseStorageDownloadTokens: token, // Can technically be anything you want
          },
        }
        const storageRef = ref(storage, `${token}.${type}`);
        try {
          uploadBytes(storageRef, file, metadata).then((snapshot) => {
            getDownloadURL(storageRef)
              .then((url) => {
                // Metadata now contains the metadata for 'images/forest.jpg'
                if (data.type === 'front')
                  setFrontUrl(url)
                else
                  setBackUrl(url)
                onSuccess(null, snapshot);
              })
              .catch((error) => {
                // Uh-oh, an error occurred!
              });
          });
        } catch (e) {
          onError(e);
        }
      }
    }
  };
  
  const onFinish = async (values) => {
    console.log(values)
    if(!frontUrl ||!backUrl) {
      message.error('Please upload document')
      return
    }
    const user = {
      photoId:{
        front:{url: frontUrl},
        back: {url: backUrl},
        type: values.document_type
      }
    }
    dispatch(appActions.updateUserData(currentUser.uid, user))
    props.onNext()
  }
  
  return (
    <Row justify={'center'} align={'center'}>
      <Col sm={20}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={[]}
          onFinish={onFinish}
        >
          <Form.Item
            rules={[{ required: true, message: 'Please select document type!' }]}
            label="Document type"
            name={'document_type'}>
            <Select
              placeholder={'Select document type'}
              options={OPTIONS}
            >
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Please upload front image of document!' }]}
            label="Front Side"
            name={'front'}>
            <Upload
              {...uploadProps}
              accept={'image/*'}
              data={{type:'front'}}
              name="front"
              maxCount={1}
              listType="picture">
              <Button
                style={{ width: '100%' }}
                block
                icon={<UploadOutlined/>}>
                Upload Front Image
              </Button>
            </Upload>
          </Form.Item>
          
          <Form.Item
            rules={[{ required: true, message: 'Please upload back image of document!' }]}
            label="Back Side"
            name={'back'}>
            <Upload
              {...uploadProps}
              data={{type:'back'}}
              accept={'image/*'}
              name="back"
              maxCount={1}
              listType="picture">
              <Button
                style={{ width: '100%' }}
                block
                icon={<UploadOutlined/>}>
                Upload Back Image
              </Button>
            </Upload>
          </Form.Item>
          
          <span> Note:  Only png/jpeg images are accepted. Image file should be less then 1MB</span>
          
          <Col span={16} offset={8} style={{ marginTop: 50 }}>
            <Form.Item>
              <Button block type="primary" htmlType="submit" size={"large"}>
                Continue
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default FinancialInformation;
