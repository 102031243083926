import { Button, Checkbox, Col, Form, Row, Select } from "antd";
import React, { useEffect } from "react";
//import appActions from "../../../modules/app/appActions";
import { useDispatch } from "react-redux";

const { Option } = Select;

const MattyAgreement = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  
  useEffect(() => {
  
  }, [])
  
  const onFinish = (values) => {
    props.onNext()
  }
  
  return (
    <Row justify={'center'} align={'center'}>
      <Col sm={20}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={[]}
          onFinish={onFinish}
        >
          
          <Col span={24}>
            <iframe
              style={{width:'100%', height: 450}}
              src={'https://firebasestorage.googleapis.com/v0/b/matty-628bf.appspot.com/o/agrrement-template.pdf?alt=media&token=2efb67a7-c40b-4379-8dcd-2e6ba9c537d8'}
            />
          </Col>
          
          <Col span={24}>
            <Form.Item
              label={''}
              rules={[{
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
              }]}
              valuePropName="checked"
              name={'accept'}>
              <Checkbox>
                <span style={{fontSize: 10}}>I have read, understood, and agree to be bound by Alpaca Securities LLC and Matty account terms, and all other terms, disclosures and disclaimers applicable to me, as referenced in the Alpaca Customer Agreement. I also acknowledge that the Alpaca Customer Agreement contains a pre-dispute arbitration clause in Section 42.</span>
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              rules={[{
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
              }]}
              label={''}
              valuePropName="checked"
              name={'sign'}>
              <Checkbox>
                <span style={{fontSize: 10}}>I understand I am signing this agreement electronically and that my electronic signature is the legal equivalent of my manual signature on this Agreement.</span>
              </Checkbox>
            </Form.Item>
          </Col>
          
          <Col span={16} offset={8} style={{ marginTop: 0 }}>
            <Form.Item>
              <Button block type="primary" htmlType="submit" size={"large"}>
                Continue
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default MattyAgreement;
