import React, { useEffect, useRef } from 'react';
import { Redirect, Switch, BrowserRouter } from 'react-router-dom';
import SimpleRoute from './../../modules/routes/SimpleRoute';
import authSelectors from './../../modules/auth/authSelectors';
import Login from "../../containers/Auth/Login";
import Signup from "../../containers/Auth/Signup";
import Register from "../../containers/Registration/index";
import Home from "../../containers/Home";
import ForgetPassword from "../../containers/Auth/ForgotPassword";

import { useSelector } from "react-redux";
import ProgressBar from "../Shared/ProgressBar";
import appSelector from "../../modules/auth/authSelectors";

function RoutesComponent(props) {
  
  const isInitialMount = useRef(true);
  
  const loading = false
  
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const authLoading = useSelector(
    authSelectors.selectLoading,
  );
  /*const isRegistered = useSelector(
    appSelector.selectIsRegistered,
  );*/
  
  
  useEffect(() => {
    //ProgressBar.start();
    
    if (!authLoading) {
      ProgressBar.done();
    } else {
      ProgressBar.start();
    }
  }, [authLoading]);
  if (authLoading)
    return <div/>
  
  return (
      <Switch>
        <Redirect exact from="/" to="/login"/>
        <SimpleRoute
          exact
          path="/login"
          key={"login"}
          currentUser={currentUser}
          component={(props) => <Login {...props}/>}
        />
        <SimpleRoute
          exact
          path="/signup"
          key={"/signup"}
          currentUser={currentUser}
          component={(props) => <Signup {...props}/>}
        />
        <SimpleRoute
          exact
          path="/register"
          key={"/register"}
          currentUser={currentUser}
          component={(props) => <Register {...props}/>}
        />
        <SimpleRoute
          exact
          path="/forget-password"
          key={"login"}
          component={(props) => <ForgetPassword {...props}/>}
        />
        <SimpleRoute
          exact
          path="/skytails"
          key={"/skytails"}
          currentUser={currentUser}
          component={(props) => <Home {...props}/>}
        />
      
      </Switch>
  );
}

export default RoutesComponent;
