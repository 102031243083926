import React, {useState} from "react";
import SectionWrapper from "../../../components/SectionWrapper";
import SideImage from "./../../../assets/frame1.png";
import Hand from "./../../../assets/hand.png";
import {Form, Image, Input, message} from "antd";
import {Link, useHistory} from "react-router-dom";
import {Label} from "../../../styles";
import {ForgetPassword, FormWrapper, LoginWrapper, Navigator, Spacer,} from "./styles";
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth";
import Button from "../../../components/Button";
import {Checkbox} from "../../../components/Checkbox/style";
//import {Auth} from "aws-amplify";
import {useDispatch, useSelector} from "react-redux";
//import authActions from "../../../modules/auth/authActions";
import authSelectors from "../../../modules/auth/authSelectors";
import {auth} from "../../../firebase";
import authSelector from "../../../modules/auth/authSelectors";
import appActions from "../../../modules/app/appActions";

const SectionContent = () => {
  return (
    <>
    </>
  );
};
const Index = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector(authSelector.selectCurrentUser)
  if(currentUser){
   // history.replace('/register')
  }

  const onFinish = async (values) => {
    const { email, password } = values;
    setLoading(true)
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        dispatch(appActions.onLogin(user)).then((data)=>{
          setLoading(false)
          history.push(data?.accountId ? '/skytails' : '/register')
        }).catch((e)=>{
          setLoading(false)
          history.push('/register')
        })
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(errorMessage);
      });
  };
  const handleGoogleSignin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          const user = result.user;
          dispatch(appActions.onLogin(user))
          history.push('/register')
        }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(errorMessage);
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  return (
    <LoginWrapper>
      <SectionWrapper image={SideImage} SectionContent={SectionContent}>
        <FormWrapper>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {/*<Button
              handleClick={handleGoogleSignin}
              className="google__btn"
              veriant="primary"
              size="medium"
              block
            >
              Log in with Google
            </Button>
            <Spacer>
              <p>or</p>
            </Spacer>*/}
            <Label>E-mail*</Label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            >
              <Input className="input" style={{height: 50}} />
            </Form.Item>
            <Label>Password*</Label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            >
              <Input.Password className="input" style={{height: 50}} />
            </Form.Item>
            <ForgetPassword>
              <Link to="/forget-password">Forget Password</Link>
            </ForgetPassword>
            <Button
              className="login__submit--btn"
              veriant="primary"
              size="medium"
              block
              type="submit"
              loading={loading}
            >
              Log in with Email
            </Button>
            <div className="remember">
              <Checkbox>Remember me!!</Checkbox>
            </div>
            <Navigator style={{marginTop: 20}}>
              New to Skytailsapp? <Link to="/signup">Sign up</Link>
            </Navigator>
          </Form>
        </FormWrapper>
      </SectionWrapper>
    </LoginWrapper>
  );
};

export default Index;
