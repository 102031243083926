import { createBrowserHistory } from 'history';
import createRootReducer from './../../src/modules/reducers';
import { applyMiddleware, createStore } from 'redux';
import initializers from './../../src/modules/initializers';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import history from './../history'

const persistConfig = {
  key: 'tube2blog',
  storage: storage,
  whitelist: [''] // which reducer want to store
};
const pReducer = persistReducer(persistConfig, createRootReducer(history));
const middlewares = applyMiddleware(thunkMiddleware);

const store = createStore(pReducer, middlewares);
const persistor = persistStore(store);
for (const initializer of initializers) {
  initializer(store);
}
export { persistor, store };

export function getHistory() {
  return history;
}

export default function getStore() {
  return store;
}
