import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import appSelector from "../../modules/app/appSelectors";
import authSelector from "../../modules/auth/authSelectors";
import { Button, message, Modal, Popconfirm, Select, Space, Table } from "antd";
import NewRequest from "../../components/NewRequest";
import React, { createContext, useEffect, useState } from "react";
import appActions from "../../modules/app/appActions";
const Pets = (props) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const user = useSelector(appSelector.selectUser)
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const positions = useSelector(appSelector.selectPositions)
  const pets = useSelector(appSelector.selectPets)
  const ReachableContext = createContext(null);
  const [modal, contextHolder] = Modal.useModal();
  const { Option } = Select;
  const [isModalOpen, openAddModal] = useState(false)
  const [petsData, setPetsData] = useState([]);
  const [dataChanged, setDataChanged] = useState(false)
  const [petData, setPetData] = useState(null)
  
  const positionColumns = [
    {
      title: 'Pet Name',
      dataIndex: 'Pet_Name',
      key: 'Pet_Name'
    },
    {
      title: 'Age',
      dataIndex: 'Pet_Age',
      key: 'Pet_Age'
    },
    {
      title: 'Policy',
      dataIndex: 'Pet_policy',
      key: 'Pet_policy',
      render: (text, record, index) => (
        <Select
          value={record.Pet_policy}
          style={{ width: 120 }}
          onChange={(value) =>{
            const data = [...petsData];
            data[index].Pet_policy = value;
            setPetsData(data);
            setDataChanged(true);
          }}
        >
          <Option value={10}>10</Option>
          <Option value={15}>15</Option>
          <Option value={20}>20</Option>
        </Select>
      ),
    },
    {
      title: 'Withdrawal Method',
      dataIndex: 'withdrawal_method',
      key: 'withdrawal_method',
      render: (text, record, index) => (
        <Select
          value={record.withdrawal_method}
          style={{ width: 240 }}
          onChange={(value) =>{
            const data = [...petsData];
            data[index].withdrawal_method = value;
            setPetsData(data);
            setDataChanged(true);
          }}
        >
          <Option value={'monthly'}>Per month (60mos)</Option>
          <Option value={'once'}>Once - in full</Option>
        </Select>
      ),
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record, index) =>
        <Space size="middle">
          <Button
            onClick={()=>{
              setPetData(petsData[index])
              openAddModal(true);
            }}
            type={'link'}>Edit</Button>
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
            <Button type={'link'}>Delete</Button>
          </Popconfirm>
        </Space>
      
    },
  ]
  
  const applyChanges = async ()=>{
    const objectsWithoutAge = petsData.filter(obj => !obj.Pet_policy || !obj.withdrawal_method);
    if(objectsWithoutAge?.length>0){
      message.error('Please select all the options');
      return;
    }
    dispatch(appActions.updatePets(petsData)).then(()=>{
      message.success('Changes Applied successfully');
    })
  }
  const handleDelete = async (id)=>{
    dispatch(appActions.deletePet(id)).then(()=>{
      message.success('Pet deleted successfully');
      dispatch(appActions.getPets(currentUser?.uid))
    })
  }
  
  const handleOk = () => {
    openAddModal(false)
    setPetData(null);
    dispatch(appActions.getPets(currentUser?.uid))
  };
  
  const areArraysEqual = (arr1, arr2) =>{
    console.log('areArraysEqual', arr1, arr2);
    if (arr1.length !== arr2.length) return false;
    
    return arr1.every((obj1, index) => {
      const obj2 = arr2[index];
      // Check if both have the same keys
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      if (keys1.length !== keys2.length) return false;
      
      // Check if all keys and values are identical
      return keys1.every(key => obj1[key] === obj2[key]);
    });
  }
  
  const handleCancel = () => {
    openAddModal(false)
    setPetData(null);
  };
  
  useEffect(() => {
    const data = [...pets];
    setPetsData(data);
  }, [pets]);
  
  console.log('data change',dataChanged)
  return (
      <div>
      <div>
        <Button
          onClick={() => {
            setPetData({'aa':'aa' });
            openAddModal(true)
          }}
          type="primary" className="mb-2" style={{ float: "right" , backgroundColor:'#6495ED', borderColor: '#6495ED'}}>Add New +</Button>
        {dataChanged &&
          <Popconfirm
            title="Are you sure you wan to apply yhese changes ?"
            description="Are you sure you wan to apply yhese changes ?"
            onConfirm={applyChanges}
            okText="Yes"
            cancelText="No"
            placement="bottomLeft"
          >
            <Button
              className="mb-2" style={{ float: "right", marginRight:10, backgroundColor: '#cb4335', color: '#fff' }}>Apply Changes</Button>
          </Popconfirm>
        }
      </div>
      <Table
        columns={positionColumns}
        dataSource={petsData}
      />
      <Modal
        width={650}
        style={{ width: 600 }}
        visible={isModalOpen}
        title="Add Pet"
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <NewRequest
          petData={petData}
          onFinish={() => handleOk()}/>
      </Modal>
    </div>
   )
}
export default Pets;
