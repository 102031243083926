import React from 'react';
import "antd/dist/antd.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'antd-country-phone-input/dist/index.css';
import 'react-pro-sidebar/dist/css/styles.css';
import {AppWrapper} from "./styles";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider, useSelector} from "react-redux";
import {store} from "./modules/store";
import 'typeface-roboto';
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss';
import history from './history';

import RoutesComponent from "./components/Route/RoutesComponent";
function App() {
  return (
    <Provider store={store}>
        <AppWrapper>
          <Router
            //@ts-ignore
            history={history}>
            <RoutesComponent/>
          </Router>
        </AppWrapper>
    </Provider>
  );
}

export default App;
