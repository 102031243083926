import React from 'react'
import { Spin } from "antd";
import { useSelector } from "react-redux";
import appSelector from "../../modules/app/appSelectors";

function Loading() {
  const loading = useSelector(appSelector.selectLoading)
  if(!loading)
    return null
  return (
    <div style={{
      position: 'fixed',
      inset:0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Spin size="large"/>
    </div>
  )
}

export default Loading
