export const between = (x, start, end) => {
  return x >= start && x <= end;
}
export const sortKeys = (obj) => {
  const keys = Object.keys(obj).sort((k1, k2) => {
    if (Number.parseFloat(k1) < Number.parseFloat(k2)) return -1;
    else if (Number.parseFloat(k1) > Number.parseFloat(k2)) return 1;
    else return 0;
  });
  return keys;
}
export const secToMin = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - (hours * 3600)) / 60);
  const seconds = Math.floor(duration - minutes * 60);
  var ret = "";
  ret += ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
  return ret;
}
export const secToMilliSecond = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - (hours * 3600)) / 60);
  const seconds = Math.floor(duration - minutes * 60);
  const millisecond = Math.floor(((duration - minutes * 60) - seconds) * 1000)
  return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2) + "." + (millisecond < 100 ? `${millisecond}0` : millisecond);
}
export const displayMilliSecond = (duration) => {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration - minutes * 60);
  const millisecond = Math.floor(((duration - minutes * 60) - seconds) * 1000)
  let ret = ''
  if (minutes > 0)
    ret += ("0" + minutes).slice(-2) + ":"
  if (minutes || seconds > 0)
    ret += ("0" + seconds).slice(-2) + "."
  ret += millisecond
  return ret
}
export const convertToSec = (time) => {
  let sec = 0.0;
  const tokens = time.split(':')
  if (tokens.length > 0) {
    sec += (Number.parseFloat(tokens[0]) * 60)
    sec += Number.parseFloat(tokens[1])
  } else {
    sec += Number.parseFloat(tokens[0])
  }
  console.log(sec)
  return sec
}
export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
export const randomIntFromInterval = (min, max) => { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}
export const formatSocialSecurity = (val) => {
  val = val.replace(/\D/g, '');
  val = val.replace(/^(\d{3})/, '$1-');
  val = val.replace(/-(\d{2})/, '-$1-');
  val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
  return val;
}
export const codes = {
  'Arizona': 'AZ',
  'Alabama': 'AL',
  'Alaska': 'AK',
  'Arkansas': 'AR',
  'California': 'CA',
  'Colorado': 'CO',
  'Connecticut': 'CT',
  'Delaware': 'DE',
  'Florida': 'FL',
  'Georgia': 'GA',
  'Hawaii': 'HI',
  'Idaho': 'ID',
  'Illinois': 'IL',
  'Indiana': 'IN',
  'Iowa': 'IA',
  'Kansas': 'KS',
  'Kentucky': 'KY',
  'Louisiana': 'LA',
  'Maine': 'ME',
  'Maryland': 'MD',
  'Massachusetts': 'MA',
  'Michigan': 'MI',
  'Minnesota': 'MN',
  'Mississippi': 'MS',
  'Missouri': 'MO',
  'Montana': 'MT',
  'Nebraska': 'NE',
  'Nevada': 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Ohio': 'OH',
  'Oklahoma': 'OK',
  'Oregon': 'OR',
  'Pennsylvania': 'PA',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  'Tennessee': 'TN',
  'Texas': 'TX',
  'Utah': 'UT',
  'Vermont': 'VT',
  'Virginia': 'VA',
  'Washington': 'WA',
  'West Virginia': 'WV',
  'Wisconsin': 'WI',
  'Wyoming': 'WY'
};
