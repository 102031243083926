import { Button, PageHeader, Steps, Col, Modal as AntModal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import { ContentWrapper } from "../../styles";
//import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import PersonalInformation from "./PersonalInformation";
import FinancialInformation from "./FinancialInformation";
import TrustedContact from "./TrustedContact";
import Identity from "./Identity";
import Affiliation from "./Affiliation";
import AlpacaAgreement from "./AlpacaAgreement";
import MattyAgreement from "./MattyAgreement";
import CompleteRegistration from "./CompleteRegistration";
import authSelector from "../../modules/auth/authSelectors";
import appSelector from "../../modules/app/appSelectors";
import authActions from "../../modules/auth/authActions";
import appActions from "../../modules/app/appActions";

const { Step } = Steps;
const { confirm } = AntModal;

const Index = (props) => {
  console.log(props)
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isRegistered = useSelector(appSelector.selectIsRegistered);
  const isAuthRegistered = useSelector(authSelector.selectRegistered);
  const [currentStep, setCurrentStep] = useState(0)
  
  const currentUser = useSelector(authSelector.selectCurrentUser)
  if(!currentUser){
    history.replace('/login')
  }
  //
  useEffect(() => {
    if (isRegistered || isAuthRegistered) {
      history.push('/skytails')
    }
  }, [isRegistered, isAuthRegistered])
  
  const onChange = (value)=>{
    if(value<currentStep) {
      setCurrentStep(value);
    }
  }

  return (
    <Row gutter={16} style={{ maxWidth: 1080, margin: 'auto' }}>
      <Col sm={24}>
        <ContentWrapper>
          <Row justify={'center'}>
            <Col span={24}>
              <Steps
                onChange={onChange}
                current={currentStep} style={{ marginTop: 60 }}>
                <Step title="Step 1"/>
                <Step title="Step 2"/>
                <Step title="Step 3"/>
                <Step title="Step 4"/>
                <Step title="Step 5"/>
                <Step title="Step 6"/>
                <Step title="Step 7"/>
                <Step title="Step 8"/>
              </Steps>
            </Col>
          </Row>
          <div style={{ marginTop: 50 }}>
            <Carousel
              showArrows={false}
              swipeable={false}
              showThumbs={false}
              selectedItem={currentStep}
              showIndicators={false}
              showStatus={false}>
              <PersonalInformation
                onNext={() => {
                  setCurrentStep(1)
                }}
              />
              <FinancialInformation
                onNext={() => {
                  setCurrentStep(2)
                }}
              />
              <TrustedContact
                onNext={() => {
                  setCurrentStep(3)
                }}
              />
              <Identity
                onNext={() => {
                  setCurrentStep(4)
                }}
              />
              <Affiliation
                onNext={() => {
                  setCurrentStep(5)
                }}
              />
              <MattyAgreement
                onNext={() => {
                  setCurrentStep(6)
                }}
              />
              <AlpacaAgreement
                onNext={() => {
                  setCurrentStep(7)
                }}
              />
              <CompleteRegistration
                onNext={() => {
                  setCurrentStep(7)
                }}
              />
            </Carousel>
          </div>
        </ContentWrapper>
      </Col>
      <Button
        onClick={()=>{
          dispatch(authActions.doLogout())
          dispatch(appActions.cleanSession())
        }}
        style={{position:'absolute', right: 20, top:20}}
        type="outline">Logout</Button>
    </Row>
  );
};

export default Index;
