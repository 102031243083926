import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
//import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../modules/app/appActions";
import authSelector from "../../modules/auth/authSelectors";

const { Option } = Select;
const OPTIONS = [
  { label: '< $25,000', value: '0,25000' },
  { label: '$20,000 - $49,999', value: '20000,49999' },
  { label: '$50,000 - $99,999', value: '50000,99999' },
  { label: '$100,000 - $499,999', value: '100000,499999' },
  { label: '$500,000 - $1,000,000', value: '500000,1000000' },
  { label: '>$1,000,000', value: '1000000,1000000' }
]

const FinancialInformation = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentUser = useSelector(authSelector.selectCurrentUser)
  
  useEffect(() => {
  
  }, [])
  
  const onFinish = (values) => {
    console.log(values)
    const user = {
      trustedContact:{
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        phone: values.phone
      }
    }
    dispatch(appActions.updateUserData(currentUser.uid, user))
    props.onNext()
  }
  
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select defaultValue={"1"} defaultActiveFirstOption={true}  value={"1"} style={{ width: 70 }}>
        <Option value="1">+1</Option>
      </Select>
    </Form.Item>
  );
  
  return (
    <Row justify={'center'} align={'center'}>
      <Col span={20}>
        <h1>{'Trusted Contact'}</h1>
      </Col>
      <Col span={20} style={{textAlign: 'left', marginBottom: 30}}>
        <span>{'A trusted contact is a person you authorize your financial firm to contact in limited circumstances, such as if there is a concern about activity in your account and they have been unable to get in touch with you. A trusted contact may be a family member, attorney, accountant or another third-party who you believe would respect your privacy and know how to handle the responsibility.'}</span>
      </Col>
      <Col sm={20}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={[]}
          onFinish={onFinish}
        >
          <Form.Item
            rules={[{ required: true, message: 'Please input first name!' }]}
            label="First Name" name={'first_name'}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Please input last name!' }]}
            label="Last Name" name={'last_name'}>
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Please input email!' }]}
            label="Email"
            name={'email'}>
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[{ required: true, message: 'Please input phone number!' }]}
          >
            <Input addonBefore={prefixSelector}/>
          </Form.Item>
          
          
          <Col span={16} offset={8} style={{ marginTop: 50 }}>
            <Form.Item>
              <Button block type="primary" htmlType="submit" size={"large"}>
                Continue
              </Button>
            </Form.Item>
          </Col>
          <Col span={16} offset={8} style={{ marginTop: 5 }}>
            <Form.Item>
              <Button
                block
                onClick={()=>{props.onNext()}}
                type="outline"
                size={"large"}>
                Skip
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default FinancialInformation;
