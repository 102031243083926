import React, {useEffect, useState} from "react";
import SectionWrapper from "../../../components/SectionWrapper/index";
import {Col, Form, Image, Input, message, Row, Button as AntButton} from "antd";
import {Label} from "../../../styles";
import {Link, useHistory} from "react-router-dom";
import {FormWrapper, Navigator, PopupDetails, PopupMsg, PopupWrapper, SignupWrapper, Spacer,} from "./styles";
import Button from "../../../components/Button";
import {signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import authActions from "../../../modules/auth/authActions";
import authSelectors from "../../../modules/auth/authSelectors";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "../../../components/Checkbox/style";
import {auth} from "../../../firebase";
//import appActions from "../../../modules/app/appActions";
import SideImage from "../../../assets/frame1.png";
import appActions from "../../../modules/app/appActions";


const PopupMessage = () => {
  return (
    <PopupWrapper>
      <PopupMsg>
        “Spread has dramatically increased the speed of my transcription
        workflow. I’ve taken on more clients than ever before - with faster turn
        arounds”
      </PopupMsg>
      <PopupDetails>
        <h3>Ayha</h3>
        <p>Freelance Transcriber</p>
      </PopupDetails>
    </PopupWrapper>
  );
};

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [codeSent, setCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [form] = Form.useForm();
  const isCodeSent = useSelector(
    authSelectors.selectConfirmation,
  );
  useEffect(() => {
    if (!codeSent)
      setCodeSent(isCodeSent)
  }, [isCodeSent])
  const SectionContent = () => {
    return (
      <Row>
        <Col
          xs={{offset: 0, span: 24}}
          md={{offset: 12, span: 12}}
        >
          {/*{!codeSent && <div style={{display: 'inline-flex', marginTop: '-8%', marginBottom: '-2%'}}>
            <h1 style={{fontSize: 44.5}}>
              Welcome to <b>Blog!</b>
            </h1>
            <Image className="image" preview={false} src={Hand} style={{marginLeft: 10, marginTop: 10}}/>
          </div>
          }*/}
          {codeSent &&
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h1 style={{fontSize: 44.5, fontFamily: 'Lato'}}>
              ENTER YOUR CODE
            </h1>
            <p className="para__secondary" style={{width: "80%"}}>
              We sent you a verification code to your email. Enter it below to start your free trial!
            </p>
          </div>
          }
        </Col>
      </Row>
    );
  };
  const onFinish = async (values) => {
    const {first_name, last_name, email, password} = values;
    const name = `${first_name} ${last_name}`
    setLoading(true)
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        await updateProfile(user, {displayName: name})
        user.displayName = name;
        user.password = password;
        user.first_name = first_name;
        user.last_name = last_name;
        dispatch(appActions.registerAccount(user))
        setLoading(false)
        history.push('/register')
      })
      .catch((error) => {
        setLoading(false)
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(errorMessage);
      });

    /*const cookies = new Cookies();
    if (codeSent) {
      if (code.length !== 6) {
        message.error('Please enter a valid code');
        return;
      }
      try {
        setLoading(true)
        const confirm = await Auth.confirmSignUp(cookies.get('a_u'), code);
        if (confirm === 'SUCCESS') {
          await cookies.remove('a_u');
          await cookies.remove('codeSent');
          dispatch(authActions.updateConfirmSent(false));
          message.success('Your free trail is active now. Please login.')
          history.push("/login");
        }
      } catch (e) {
        setLoading(false)
        message.error('invalid code')
        console.log(e)
      }
      return
    }
    const {name, email, password} = values;
    try {
      const user = await Auth.signUp({
        username: email,
        password,
        attributes: {
          name,
          email,
          'custom:marketing': values.marketing === true ? '1' : '0',
        },
      });
      setCodeSent(true);
      cookies.set('a_u', user.user.username, {path: '/'});
      cookies.set('codeSent', true, {path: '/'});
      dispatch(authActions.updateConfirmSent(true))
      //history.push("/login");
      message.success(
        "We have sent a verification msg to your email please verify."
      );
    } catch (error) {
      message.error(error.message);
    }*/
  };
  const handleGoogleSignup = async () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          console.log('result', result)
          const user = result.user;
          dispatch(appActions.registerAccount(user))
          history.push('/register')
        }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        message.error(error);
      });
    } catch (error) {
      console.log(error)
      message.error(error.message);
    }
  };
  const handleChange = (e) => {
  };
  const checkConsent = (_, value) => {
    if (value) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('You must agree to terms'));
  };
  return (
    <SignupWrapper>
      <SectionWrapper
        Popup={PopupMessage}
        SectionContent={SectionContent}
        image={SideImage}
      >
        <FormWrapper>
          <Form
            form={form}
            name="nest-messages" onFinish={onFinish}>
            {/*<Button
              className="google__btn"
              handleClick={handleGoogleSignup}
              veriant="primary"
              size="medium"
              block
            >
              Sign up with Google
            </Button>
            <Spacer>
              <p>or</p>
            </Spacer>*/}
            <div className="d-flex justify-content-between">
              <div className="w-50 me-2">
                <Label>First name*</Label>
                <Form.Item
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}
                >
                  <Input className="input"/>
                </Form.Item>
              </div>
              <div className="w-50 ms-2">
                <Label>Last name*</Label>
                <Form.Item
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: false,
                    },
                  ]}
                >
                  <Input className="input"/>
                </Form.Item>
              </div>
            </div>
            <Label>E-mail*</Label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            >
              <Input className="input"/>
            </Form.Item>
            <Label>Password*</Label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            >
              <Input.Password className="input"/>
            </Form.Item>
            <Label>Conform Password*</Label>
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: false,
                },
                {
                  validator: (_, value, callback) => {
                    if (!value || value === form.getFieldValue('password')) {
                      return Promise.resolve();
                    }
                    return Promise.reject('Passwords do not match');
                  },
                },
              ]}
            >
              <Input.Password className="input"/>
            </Form.Item>
            {/*<Form.Item name="marketing" valuePropName="checked">
              <Checkbox onChange={handleChange} style={{marginTop: "1px"}}>
                Consent to marketing (no spam!)
              </Checkbox>
            </Form.Item>*/}
            {/*<Form.Item
              style={{marginTop: -20}}
              valuePropName="checked"
              rules={[
                {
                  validator: checkConsent,
                },
              ]}
              name="terms">
              <Checkbox onChange={handleChange}>
                Consent to T&C’s
              </Checkbox>
            </Form.Item>*/}
            <Button
              className="signup__submit--btn"
              veriant="primary"
              size="medium"
              block
              type="submit"
              loading={loading}
            >
              Create Account
            </Button>
            <Navigator>
              Already a loving user? <Link to="/login">Log in</Link>
            </Navigator>
          </Form>
        </FormWrapper>
      </SectionWrapper>
    </SignupWrapper>
  );
};

export default Index;
