import { Button, Col, Form, message, Row, Select, Spin } from "antd";
import React, { useEffect } from "react";
import { LoadingOutlined } from '@ant-design/icons';

//import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../modules/app/appActions";
import authSelector from "../../modules/auth/authSelectors";
import appSelector from "../../modules/app/appSelectors";
import { useHistory } from "react-router-dom";

const FinancialInformation = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const isLoading = useSelector(appSelector.selectLoading)
  const antIcon = <LoadingOutlined style={{ fontSize: 150 }} spin/>;
  const isRegistered = useSelector(appSelector.selectIsRegistered);
  const user = useSelector(appSelector.selectUser)
  useEffect(() => {
    if (isRegistered || user?.accountId) {
      history.push('/skytails')
    }
  }, [isRegistered, user])
  
  const onRegister = () => {
    dispatch(appActions.createAccount(currentUser.uid))
  }
  
  return (
    <>
      <Row justify={'center'} align={'center'}>
        <Col sm={20}>
          <img
            style={{ width: 200, height: 200, marginLeft: 40 }}
            src={require('./../../assets/logo.png')}/>
          <div
            style={{
              fontSize: 24,
              fontWeight: 'bold',
              color: '#FF6616',
              textAlign: 'center',
              marginTop: 40
            }}>
            {'Make it Happen.'}
          </div>
          <Button
            onClick={onRegister}
            style={{ marginTop: 60, height: 60, width: 300 }}
            type={'primary'}
            shape="round"
            size={"large"}>
            Start Trading and Saving!
          </Button>
        </Col>
      </Row>
      {isLoading && <div
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={(e) => {
          e.preventDefault()
        }}>
        <Spin indicator={antIcon}/>
      </div>}
    </>
  );
};

export default FinancialInformation;
