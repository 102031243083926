import {createSelector} from 'reselect';

const selectRaw = (state) => state.auth;

const selectCurrentUser = createSelector(
  [selectRaw],
  (auth) => auth.currentUser,
);

const selectSignedIn = createSelector(
  [selectCurrentUser],
  (currentUser) =>
    Boolean(currentUser) && Boolean(currentUser.id),
);

const selectLoading = createSelector([selectRaw], (auth) =>
  Boolean(auth.loading),
);
const selectConfirmation = createSelector([selectRaw], (auth) =>
  Boolean(auth.confirmSent),
);

const selectLoadingInit = createSelector(
  [selectRaw],
  (auth) => Boolean(auth.loadingInit),
);
const selectBalance = createSelector(
  [selectRaw],
  (auth) => auth.billing,
);
const selectRegistered = createSelector(
  [selectRaw],
  (auth) => auth.registered,
);

const selectErrorMessage = createSelector(
  [selectRaw],
  (auth) => auth.errorMessage,
);

const selectors = {
  selectLoadingInit,
  selectLoading,
  selectSignedIn,
  selectCurrentUser,
  selectErrorMessage,
  selectRaw,
  selectBalance,
  selectConfirmation,
  selectRegistered
};

export default selectors;
