import styled, {css} from "styled-components";
import {Button as Btn} from "antd";

export const Button = styled(Btn)`
  font-family: Lato;
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
  props.veriant === "primary" &&
  css`
      background: #4a60f4;
      box-shadow: 0px 20px 30px -10px rgba(74, 96, 244, 0.3);
      border-radius: 5px;
      color: #fff;
      &:hover {
        background: #afbaff;
        color: #4a60f4;
        border: 1px solid #4a60f4;
      }
    `};
  ${(props) =>
  props.veriant === "success" &&
  css`
      background: #68d725;
      box-shadow: 0px 20px 30px -10px rgba(74, 96, 244, 0.3);
      border-radius: 5px;
      color: #fff;
      border: 1px solid #68d725;
      &:hover {
        background: #afbaff;
        color: #fff;
      }
    `};
  ${(props) =>
  props.veriant === "secoundary" &&
  css`
      border: 1px solid #4a60f4;
      box-sizing: border-box;
      border-radius: 5px;
      background: transparent;
      color: #4a60f4;
      &:hover {
        background: #4a60f4;
        border: 1px solid #2c304b;
        color: #fff;
      }
    `};
  ${(props) =>
  props.size === "large" &&
  css`
      font-weight: bold;
      padding: 15px;
      font-size: 20px;
      height: unset;
      padding-left: 40px;
      padding-right: 40px;
    `}
  ${(props) =>
  props.size === "small-medium" &&
  css`
      font-weight: bold;
      padding: 7px;
      font-size: 18px;
      height: unset;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 10px;
      border-radius: 3px;
    `}  
  ${(props) =>
  props.size === "medium" &&
  css`
      font-weight: bold;
      padding: 10px;
      font-size: 20px;
      height: unset;
      padding-left: 40px;
      padding-right: 40px;
    `}
  ${(props) =>
  props.size === "small" &&
  css`
      font-weight: bold;
      padding: 5px;
      font-size: 14px;
      height: 35px;
      padding-left: 10px;
      padding-right: 10px;
    `}
  &.buy-more {
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;
