import React, { useEffect, useState } from "react";
import { Form, Image, Input, Upload, message, Select, DatePicker, InputNumber } from "antd";
import Button from "./../components/Button";
import { UploadOutlined } from '@ant-design/icons';
import { storage, ref } from "../firebase";
import { uploadBytes, getDownloadURL } from "firebase/storage";
import uuid from "uuid-v4";
import appActions from "../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "../modules/auth/authSelectors";
import moment from "moment";
import breeds from '../types.json';
import appSelectors from "../modules/app/appSelectors";

function NewRequest(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [otherIssues, setOtherIssue] = useState(false)
  const [isPureBred, setPureBred] = useState(true)
  const [hasFacility, setHasFacility] = useState(false)
  const [petBreed, setPetBreed] = useState('')
  
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const loading = useSelector(
    appSelectors.selectLoading,
  );
  
  useEffect(() => {
    if(props?.petData && !props?.petData?.id){
      form.resetFields();
    }
    else if(props?.petData){
      form.setFieldsValue({
        "Pet_AdoptionDate": props?.petData?.Pet_AdoptionDate ? moment(props?.petData?.Pet_AdoptionDate) : null,
        "Pet_Age": Number.parseInt(props?.petData?.Pet_Age),
        "Pet_Facility": props?.petData?.Pet_Facility,
        "Pet_Issues": props?.petData?.Pet_Issues,
        Pet_Name: props?.petData?.Pet_Name,
        "Pet_Sex": props?.petData?.Pet_Sex,
        "Pet_Type": props?.petData?.Pet_Type,
        "Pet_policy": props?.petData?.Pet_policy,
        "Pet_purebred": props?.petData?.Pet_purebred,
        "withdrawal_method": props?.petData?.withdrawal_method,
      })
    }
  }, [form,props?.petData]);
  const onFinish = (values) => {
    let data = {
      ...values,
      Pet_AdoptionDate: moment(values.Pet_AdoptionDate).format('YYYY-MM-DD'),
    }
    if(props.petData && props.petData.id){
      data = {
        ...props.petData,
        ...data
      }
    }
    dispatch(appActions.addPet(currentUser.uid,data )).then(() => {
        message.success(`Request created successfully`);
        props.onFinish()
        form.resetFields();
      })
      .catch((error) => {
        console.log(error)
      })
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const uploadProps = {
    name: 'file',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    
  };
  return (
    <div style={{ width: 600 }}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8}}
        wrapperCol={{ span: 15 }}
        /*initialValues={{ remember: true }}*/
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onChange={(values)=>{
          console.log('values', values)
        }}
      >
        <Form.Item
          label="My Pet is"
          name="Pet_Type"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <Select
            onChange={()=>{
              form.setFieldsValue({'Pet_purebred': true});
              setPureBred(true)
            }}
            options={[
                { value: 'dog', label: 'Dog' },
                { value: 'cat', label: 'Cat' },
                { value: 'bird', label: 'Bird' },
                { value: 'reptile', label: 'Reptile / Amphibian' },
                { value: 'other', label: 'Other' },
            ]}/>
        </Form.Item>
        <Form.Item
          label="My pet's name is"
          name="Pet_Name"
          rules={[{ required: true, message: 'Please input pet name!' }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label="My pet's age is"
          name="Pet_Age"
          rules={[{ required: true, message: 'Please input pet age' }]}
        >
          <div className="d-flex">
          <InputNumber value={form.getFieldValue('Pet_Age')}/>
          
          <Select
            style={{width: 100, marginLeft: 10}}
            options={[
              { value: true, label: 'Days' },
              { value: false, label: 'Month' },
              { value: false, label: 'Year' },
            ]}/>
          </div>
        </Form.Item>
        <Form.Item
          label="My pet's sex is"
          name="Pet_Sex"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <Select
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
            ]}/>
        </Form.Item>
        <Form.Item
          label="My pet is a Purebred"
          name="Pet_purebred"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <Select
            onChange={(value)=>{
              setPureBred(value);
            }}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}/>
        </Form.Item>
        {!isPureBred && <Form.Item
          label="My pet's mix/cross breed is"
          name="Pet_Breed"
          rules={[{ required: true, message: 'Please input breed' }]}
        >
          {form.getFieldValue('Pet_Type') === 'other' &&  <Input/>}
          {form.getFieldValue('Pet_Type') !== 'other' && <Select
            onChange={(value)=>{
              form.setFieldValue('Pet_Breed', value)
              setPetBreed(value)
            }}
            options={(breeds[form.getFieldValue('Pet_Type')] || []).map((val)=>({
            label: val,
            value: val
          }))}/>}
          {petBreed === 'Other' &&  <Input placeholder={'Enter your pet breed'} style={{marginTop: 10}}/>}
        </Form.Item>}
        
        <Form.Item
          label="We are together since"
          name="Pet_AdoptionDate"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <DatePicker format={'DD/MM/yyyy'}/>
        </Form.Item>
        
        <Form.Item
          label="My pet has issues"
          name="Pet_Issues"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <Select
            mode="multiple"
            size={"small"}
            placeholder="Please select"
            onChange={(values)=>{
              if(values.includes('Pet_OtherIssue')) {
                setOtherIssue(true);
              }
              else {
                setOtherIssue(false);
              }
            }}
            options={[
              { value: 'Pet_NoIssue', label: 'No issues' },
              { value: 'Pet_HeadIssue', label: 'Head Issues' },
              { value: 'Pet_TummyIssue', label: 'Tummy Issues' },
              { value: 'Pet_LegIssue', label: 'Leg Issues' },
              { value: 'Pet_ParasiteIssue', label: 'Parasites' },
              { value: 'Pet_ChronicIssue', label: 'Chronic Issues' },
              { value: 'Pet_OtherIssue', label: 'Other Issues' },
            ]}
          />
        </Form.Item>
        
        {otherIssues && <Form.Item
          label="Other Issues"
          name="Pet_OtherIssueDesc"
          rules={[{ required: true, message: 'Please input Other Issues' }]}
        >
          <Input/>
        </Form.Item>}
        
        <Form.Item
          label="I am offered this policy by"
          name="Pet_Facility"
          rules={[{ required: true, message: 'Please select' }]}
        >
          <Select
            onChange={(value)=>{
              setHasFacility(value);
            }}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}/>
        </Form.Item>
        {hasFacility && <Form.Item
          label="Facility Name is"
          name="Pet_FavilityName"
          rules={[{ required: true, message: 'Please input name' }]}
        >
          <Input/>
        </Form.Item>}
       
        <div style={{ width: '100%', display: 'grid', padding: '30px 20%' }}>
          <Button
            veriant="primary"
            size="small"
            type={'submit'}
            loading={loading}
          >
            {props.petData && props.petData.id ? 'Save Pet' : 'Add New Pet'}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default NewRequest
