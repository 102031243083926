import React from "react";
import SectionWrapper from "../../../components/SectionWrapper";
import SideImage from "./../../../assets/frame1.png";
import {Form, Input, message} from "antd";
import {Label} from "./../../../styles";
import {ForgetPasswordWrapper, FormWrapper, Navigator} from "./styles";
import Button from "../../../components/Button";
import {Link, useHistory} from "react-router-dom";
import appActions from "../../../modules/app/appActions";
import { useDispatch } from "react-redux";

const SectionContent = () => {
  return (
    <>
      <h3 className="text-center">Reset Your Password</h3>
      <p className="para__secondary">Reset your password details below :)</p>
    </>
  );
};

const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const onFinish = async (values) => {
    console.log(' values ', values)
    try {
      await dispatch(appActions.forgotPassword(values.email))
      message.success("Forgot password email sent on your email.");
      history.replace('/login', {email: values.email})
    } catch (e) {
      console.log(e);
      message.error("Wrong old password");
    }

  };
  const handleClick = () => {
  };
  const handleChange = (e) => {
  };
  return (
    <ForgetPasswordWrapper>
      <SectionWrapper image={SideImage} SectionContent={SectionContent}>
        <FormWrapper>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Label>Enter your email</Label>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: false,
                },
              ]}
            >
              <Input className="input" onChange={handleChange}/>
            </Form.Item>

            <Button
              className="forget__password__submit--btn"
              handleClick={handleClick}
              veriant="primary"
              size="large"
              block
              type="submit"
            >
              Reset Password
            </Button>
            <Navigator>
              <Link to="/login">Log In</Link>
              <Link to="/signup">Sign up</Link>
            </Navigator>
          </Form>
        </FormWrapper>
      </SectionWrapper>
    </ForgetPasswordWrapper>
  );
};

export default Index;
