import { Upload, Button, Col, DatePicker, Form, Input, Row, Select, message, Typography, List } from "antd";
import React, { useEffect, useState } from "react";
//import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import appActions from "../../modules/app/appActions";
import authSelector from "../../modules/auth/authSelectors";

const { Option } = Select;
const OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

const FinancialInformation = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const [affiliated, setAffiliated] = useState(false)
  
  useEffect(() => {
  
  }, [])
  
  const onFinish = (values) => {
    console.log(values)
    const user = {
      disclosures: {
        is_control_person: values.affiliated,
        is_affiliated_exchange_or_finra: values.is_affiliated_exchange_or_finra ? values.is_affiliated_exchange_or_finra : false,
        is_politically_exposed: values.is_politically_exposed ? values.is_politically_exposed : false,
        immediate_family_exposed: values.immediate_family_exposed ? values.immediate_family_exposed : false
      }
    }
    dispatch(appActions.updateUserData(currentUser.uid, user))
    props.onNext()
  }
  
  return (
    <Row justify={'center'} align={'center'}>
      <Col span={16}>
        <List
          size="small"
          bordered
          header={<h3 style={{ textAlign: 'left' }}>Do any of the following apply to you or a member of your
            family?</h3>}
          dataSource={[
            'Affiliated or work with a US registered broker-dealer or FINRA',
            'Senior executive at or a 10% or greater shareholder of a publicly traded company',
            'I am, or a immediate family member/relative is a senior political figure'
          ]}
          renderItem={item => (
            <List.Item>
              {item}
            </List.Item>
          )}
        />
      </Col>
      <Col sm={16} style={{ marginTop: 30 }}>
        <Form
          form={form}
          layout="vertical"
          initialValues={[]}
          onFinish={onFinish}
        >
          
          <Form.Item
            rules={[{ required: true, message: 'Please select option!' }]}
            label="Select option"
            name={'affiliated'}>
            <Select
              placeholder={'Select option'}
              options={[
                { label: 'None of these apply', value: false },
                { label: 'Yes, this applied', value: true }
              ]}
              onChange={(value) => setAffiliated(value)}
            >
            </Select>
          </Form.Item>
          {affiliated && <>
            <Form.Item
              rules={[{ required: true, message: 'Please select option!' }]}
              label="Are you or an immediate family member affiliated with or
            employed by a stock exchange, regulatory body, member firm of an exchange, FINRA or a municipal securities
            broker-dealer?"
              name={'is_affiliated_exchange_or_finra'}>
              <Select
                placeholder={'Select option'}
                options={OPTIONS}
              >
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: 'Please select option!' }]}
              label="Are you or an immediate family member an officer or 10% or greater shareholder of a publicly traded company, subject to the US Securities Exchange Act 1934?"
              name={'immediate_family_exposed'}>
              <Select
                placeholder={'Select option'}
                options={OPTIONS}
              >
              </Select>
            </Form.Item>
            
            <Form.Item
              rules={[{ required: true, message: 'Please select option!' }]}
              label="Are you or an immediate family member currently or formerly a Politically Exposed Person or Public Official?"
              name={'is_politically_exposed'}>
              <Select
                placeholder={'Select option'}
                options={OPTIONS}
              >
              </Select>
            </Form.Item>
          </>}
          
          <Form.Item>
            <Button block type="primary" htmlType="submit" size={"large"}>
              Continue
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default FinancialInformation;
