import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
//import appActions from "../../../modules/app/appActions";
import { useDispatch, useSelector } from "react-redux";
import appActions from "../../modules/app/appActions";
import authSelector from "../../modules/auth/authSelectors";
import moment from "moment";
import ScrollToTop from "react-scroll-to-top";
import { formatSocialSecurity } from "../../Utils";

const { Option } = Select;
const OPTIONS = [
  { label: '< $25,000', value: '0,25000' },
  { label: '$20,000 - $49,999', value: '20000,49999' },
  { label: '$50,000 - $99,999', value: '50000,99999' },
  { label: '$100,000 - $499,999', value: '100000,499999' },
  { label: '$500,000 - $1,000,000', value: '500000,1000000' },
  { label: '>$1,000,000', value: '1000000,1000000' }
]

const FinancialInformation = (props) => {
  const dispatch = useDispatch();
  const [empStatus, setEmpStatus] = useState('')
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const [form] = Form.useForm();
  
  const onFinish = (values) => {
    console.log(values)
    const user = {
      identity:{
        fundingSource: values.funding_source,
        minIncome: values.annual_income.split(',')[0],
        maxIncome: values.annual_income.split(',')[1],
        minAssets: values.assets.split(',')[0],
        maxAssets: values.assets.split(',')[1],
        ssn: formatSocialSecurity(values.ssn),
        dob: moment(values.birthday).format('YYYY-MM-DD'),
      },
      employment: {
        employment_status: values.employment_status ? values.employment_status : '',
        employment_position: values.job_title ? values.job_title : '',
        employer_name: values.employer_name ? values.employer_name : '',
        employer_address: values.employer_address ? values.employer_address : '',
      }
    }
    dispatch(appActions.updateUserData(currentUser.uid, user))
    props.onNext()
  }
  
  return (
    <Row justify={'center'} align={'center'}>
      <ScrollToTop/>
      <Col sm={20}>
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={[]}
          onFinish={onFinish}
        >
          <Form.Item
            rules={[
              { required: true, message: 'Date of birth is required' },
              {
                validator: (_, value) =>
                moment().diff(value, 'years') >18 ? Promise.resolve() : Promise.reject(new Error('You must be 18 or above to use this app.')),
              }
              ]}
            label="Date of Birth"
            name={'birthday'}>
            <DatePicker
              format={'MM/DD/yyyy'}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Valid SSN is required', max: 9, min:9 }]}
            label="SSN (without '-')" name={'ssn'}>
            <Input type={'number'} maxLength={9}/>
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Annual income is required' }]}
            label="Annual Income" name={'annual_income'}>
            <Select
              placeholder={'Select your annual income'}
              options={OPTIONS}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Asset detail is required' }]}
            label="Investible / Liquid Assets" name={'assets'}>
            <Select
              placeholder={'Select your investible/liquid assets'}
              options={OPTIONS}
            />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: 'Funding source is required' }]}
            label="Account Funding Source" name={'funding_source'}>
            <Select mode={'multiple'} placeholder={'Select your funding source'}>
              <Option value={'employment_income'}>{'Employment Income'}</Option>
              <Option value={'investments'}>{'Investments'}</Option>
              <Option value={'inheritance'}>{'Inheritance'}</Option>
              <Option value={'business_income'}>{'Business Income'}</Option>
              <Option value={'savings'}>{'Savings'}</Option>
              <Option value={'family'}>{'Family'}</Option>
            </Select>
          </Form.Item>
          
          <Form.Item
            rules={[{ required: true, message: 'Employment status is required' }]}
            label="Employment Status"
            name={'employment_status'}>
            <Select
              placeholder={'Select your employment status'}
              options={[
                { label: 'Employed', value: 'Employed' },
                { label: 'Unemployed', value: 'Unemployed' },
                { label: 'Student', value: 'Student' },
                { label: 'Retired', value: 'Retired' }
              ]}
              onChange={(value, option) => setEmpStatus(value)}
            >
            </Select>
          </Form.Item>
          {empStatus === 'Employed' &&
            <>
              <Form.Item
                rules={[{ required: true, message: 'Name of Employer is required' }]}
                label="Name of Employer"
                name={'employer_name'}>
                <Input/>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Employment address is required' }]}
                label="Employer Address"
                name={'employer_address'}>
                <Input/>
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: 'Job title is required' }]}
                label="Occupation / Job Title"
                name={'job_title'}>
                <Input/>
              </Form.Item>
            </>
          }
          
          
          <Col span={16} offset={6} style={{ marginTop: 50 }}>
            <Form.Item>
              <Button block type="primary" htmlType="submit" size={"large"}>
                Continue
              </Button>
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Row>
  );
};

export default FinancialInformation;
