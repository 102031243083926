import styled from "styled-components";

export const ForgetPasswordWrapper = styled("div")`
  .forget__password__form--label {
    margin-top: 15px;
  }
  .forget__password__submit--btn {
    margin-top: 60px;
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
`;
export const FormWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  // min-height: 50vh;
  form {
    width: 100%;
  }
`;
export const Navigator = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  a {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    text-decoration-line: underline;
    color: #5a6ffb;
  }
`;
