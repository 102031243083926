// App.js (Frontend code)

import React, { useCallback, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import appSelector from "../modules/app/appSelectors";
import { Button, Card, Col, message, Row, Select } from "antd";
import appActions from "../modules/app/appActions";
import authSelector from "../modules/auth/authSelectors";

function PlaidLinkComponent({ userId, accountId, name, account , onFinish}) {
  console.log('user', userId)
  
  const [token, setToken] = useState('');
  const getToken = async ()=>{
    const response = await axios.post(`https://us-central1-matty-628bf.cloudfunctions.net/api/create_link_token`,
      {userId, environment:'production'},
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    setToken(response.data.link_token)
    console.log('Success: ', response.data);
  }
  
  useEffect( () => {
    getToken();
  }, []);
  
  
  
  const onSuccess = useCallback(async (publicToken, metadata) => {
    // Send public_token to your server to exchange it for an access token
    console.log('metadata', metadata)
    try {
      const response = await axios.post(`https://us-central1-matty-628bf.cloudfunctions.net/api/create_processor_token`,
        {public_token: publicToken, account_id: metadata.accounts[0].id, environment: 'production'},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      console.log('response 1', response)
      const response2 = await axios.post(`https://us-central1-matty-628bf.cloudfunctions.net/api/create-ach_rel`,
        {token: response.data.processor_token, accountId: accountId, name: name, environment: 'production'},
        {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      console.log('response 2', response2.data)
      onFinish();
      //getAccountInfo();
    } catch (error) {
      console.error('Error: ', error);
    }
  }, []);
  
  const { open, ready } = usePlaidLink({
    token: token,  // you'll get this token from your backend
    onSuccess,
  });
  
  return (
    <div>
      <Button
        type={'primary'}
        size={'large'}
        style={{backgroundColor: '#1890ff', borderRadius: 5, borderColor: '#1890ff'}}
        onClick={() => open()}
        block>
        Connect Bank Account
      </Button>
    </div>
  );
}

export default function LinkPlaid({ userId , accountId, name}) {
  const dispatch = useDispatch();
  const account = useSelector(appSelector.selectAccount)
  const currentUser = useSelector(authSelector.selectCurrentUser)
  const user = useSelector(appSelector.selectUser)
  const [achRelationShip, setAchRelationShip] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState('One Time Fee $12000');

  const getAchInfo = async ()=>{
    const response = await axios.get(`https://us-central1-matty-628bf.cloudfunctions.net/api/get-ach_rel?accountId=${accountId}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    if(response.data.ok){
      setAchRelationShip(response.data.data)
    }
  }
  
  const createTransfer = (accountId, amount, id, achId, policy)=>{
    dispatch(appActions.createTransfer(accountId, amount, id, achId, policy)).then(()=>{
      message.success('Successfully activated your policy');
      dispatch(appActions.getAccount(currentUser))
    }).catch((e)=>{
      message.error(e);
    })
  }
  
  useEffect(()=>{
    dispatch(appActions.getAccount(currentUser))
    getAchInfo();
  },[])
  console.log('account', account);
  return (
    <div className="p-4">
      <Card title="Account Details" bordered={false}>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Account Status"
            >
              {account?.status}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Account Value"
            >
              {account?.equity}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Buying Power"
            >
              {account?.cash}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Cash Value"
            >
              {account?.cash_withdrawable}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Bank Account"
            >
              {achRelationShip ? `${achRelationShip.bank_account_type.toUpperCase()} ${achRelationShip.bank_account_number.slice(-4)}` : ''}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Bank Account Status"
            >
              {achRelationShip ? achRelationShip.status : ''}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Pending Transfer"
            >
              {account.pending_transfer_in ? Number.parseFloat(account.pending_transfer_in).toFixed(2) : 0}
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card
              type="inner"
              title="Pending Withdraw"
            >
              {account.pending_transfer_out ? Number.parseFloat(account.pending_transfer_out).toFixed(2) : 0}
            </Card>
          </Col>
          {/*<Col xs={24}>
            <Card
              type="inner"
              title="Pet Policy"
            >
              {user?.policy && <h5>{user?.policy}</h5>}
              {!user?.policy &&
                <div>
                  <label>Policy:</label>
                  <Select
                    value={selectedPolicy}
                    onChange={(value)=>{setSelectedPolicy(value)}}
                    className={'ms-3'}
                    defaultActiveFirstOption
                    placeholder={'Please select a policy'}
                    options={[
                      {label: 'Pay policy in full- $1200 one-time fee', value: 'One Time Fee $12000'},
                      {label: 'Pay monthly - $10 on auto draft', value: 'Monthly $10'},
                      {label: 'Pay monthly - $15 on auto draft', value: 'Monthly $15'},
                      {label: 'Pay monthly - $20 on auto draft', value: 'Monthly $20'},
                    ]}
                  >
                  </Select>
                  <Button
                    onClick={(e)=>{
                      createTransfer(
                        account.id,
                        selectedPolicy.split(' ').pop().replace('$',''),
                        currentUser?.uid,
                        achRelationShip?.id,
                        selectedPolicy
                      )
                    }}
                    disabled={!account || account.status !== 'ACTIVE' || !achRelationShip }
                    size="large" className="ms-5" type="primary">Activate Policy</Button>
                </div>
              }
            </Card>
          </Col>*/}
          <Col xs={24}>
            <div className="d-flex justify-content-center m-5">
            {account && account.status === 'ACTIVE' && !achRelationShip &&
              <PlaidLinkComponent
                onFinish={()=>{
                  getAchInfo()
                }}
                userId={userId} accountId={accountId} name={name} account={account}/>
            }
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
