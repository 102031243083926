import React from "react";
import {Row, Col} from "antd";
import {Image} from "antd";
import Logo from "./../../assets/skytails_logo.png";
import {
  SectionWrapper,
  WrapperLeft,
  WrapperRight,
  WrapperRightInner,
  SectionTopContent,
  WrapperLeftInner,
} from "./styles";
import {Link} from "react-router-dom";

const Index = (props) => {
  const {image, SectionContent, Popup, bgColor, LeftContentDetails, user} = props;
  return (
    <SectionWrapper>
      <Row>
        {image && <Col span={12} xs={24} md={12}>
          <WrapperLeft bgColor={bgColor}>
            <WrapperLeftInner>
              {LeftContentDetails ?
                <LeftContentDetails name={user && user.attributes ? user.attributes.name : ''}/> : null}
              <Image
                className="image image-no-margin"
                preview={false}
                src={image}
              />
              {/*{Popup ? <Popup/> : null}*/}
            </WrapperLeftInner>
          </WrapperLeft>
        </Col>
        }
        <Col
          span={8}
          offset={2}
          xs={{offset: image ? 1 : 0, span: 22}}
          md={{offset: image ? 2 : 0, span: image ? 8 : 16}}
        >
          <WrapperRight>
            <Link to="/">
              <Image style={{width: 400, margin: 'auto', marginBottom: -50, marginTop: -40}}  preview={false} src={Logo}/>
            </Link>
            <WrapperRightInner>
              <SectionTopContent>
                {SectionContent ? <SectionContent/> : null}
              </SectionTopContent>
              {props.children}
            </WrapperRightInner>
            <div className="position-fixed" style={{bottom: 20}}><h6>Powered by Matt AI Inc.</h6></div>
          </WrapperRight>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

export default Index;
