import authActions from "./../auth/authActions";

const initialData = {
  currentUser: null,
  loadingInit: true,
  loading: false,
  errorMessage: null,
  confirmSent: false,
  registered: false,
  billing: {balance: 0},
};

export default (state = initialData, {type, payload}) => {

  if (type === authActions.AUTH_INIT_SUCCESS) {
    const currentUser = payload.currentUser || null;
    return {
      ...state,
      currentUser,
      errorMessage: null,
      loadingInit: false,
      loading: false,
      registered: payload.registered,
      billing: payload.billing || {balance: 0}
    }
  }

  if (type === authActions.AUTH_INIT_START) {

    return {
      ...state,
      errorMessage: null,
      loadingInit: true,
      loading: true
    }
  }
  if (type === authActions.AUTH_LOADING) {
    return {
      ...state,
      loading: true
    }
  }
  if (type === authActions.BALANCE_FETCH_SUCCESS) {
    return {
      ...state,
      billing: payload.billing || {},
      loading: false,
    }
  }
  if (type === authActions.BALANCE_FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      billing: {}
    }
  }
  if (type === authActions.AUTH_LOADING_STOP) {
    return {
      ...state,
      loading: false
    }
  }

  if (type === authActions.AUTH_INIT_ERROR) {
    return {
      ...state,
      currentUser: null,
      errorMessage: payload || null,
      registered: false,
      loadingInit: false,
      loading: false,
    };
  }
  if (type === authActions.AUTH_CONFIRM_SENT) {
    return {
      ...state,
      confirmSent: payload.confirmSent
    };
  }
  if (type === authActions.AUTH_LOGOUT_SUCCESS) {
    return {
      ...state,
      currentUser: null,
      errorMessage: payload || null,
      loadingInit: false,
      loading: false,
      registered: false
    };
  }
  return state;
}
